import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Layout from "src/components/layout"
import SubpageBanner from "src/components/SubpageBanner"
import SEO from "src/components/seo"
import Button from "src/components/Button"
import Breadcrumbs from "src/components/Breadcrumbs"
import Sidebar from "src/components/Sidebar"
import SimpleText from "src/components/simple-text"
import qrg from "src/images/excellerator-quick-reference-guide.pdf";

const breadcrumbs = [
  { url: "development", handle: "Development" },
  {
    url: "development/quick-reference-guide",
    handle: "Quick Reference Guide",
  },
]

const QuickReferenceGuide = () => (
  <StaticQuery
    query={graphql`
      query {
        one: file(relativePath: { eq: "excellerator-reference.jpg" }) {
          childImageSharp {
            fixed(quality: 100, width: 200) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        background: file(relativePath: { eq: "discarded-cells-banner.jpg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => {
      const one = data.one.childImageSharp.fixed
      const background = data.background.childImageSharp.fluid

      return (
        <Layout>
          <SEO title="Quick Reference Guide - ExCellerator" />
          <SubpageBanner image={background} heading="Information for Use - Quick Reference Guide" />
          <div className="wrapper wrapper--narrow p-bottom p-sides">
            <Breadcrumbs items={breadcrumbs} />
            <div className="split-contain wrapper">
              <div className="split-contain__sidebar">
                <Sidebar
                  image={one}
                />
              </div>
              <div className="split-contain__contain">
                <SimpleText
                  heading="Quick Reference Guide"
                  copy="The exCellerator is a simple addition to the ThinPrep Pap Test. Use this Quick Reference Guide to help clinicians remember when, how, and why to use exCellerator during their regular testing steps."
                />
                <div className="center"><Button link={qrg} download={true} copy="Download the guide now" /></div>
              </div>
            </div>
          </div>
        </Layout>
      )
    }}
  />
)

export default QuickReferenceGuide
